import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import Layout from '../modules/Layout'

const generalSettings = graphql`
query{
  contentfulGeneralSettings{
    storeCode{
      storeCode
    }
  }
}
`
const StorePage = () => {
  const { contentfulGeneralSettings } = useStaticQuery(generalSettings)
  const regexp = /.+?(?=<\/div><script async)/.exec(contentfulGeneralSettings.storeCode.storeCode)

  return <Layout shopPage >
    {() => {
      return <div dangerouslySetInnerHTML={{__html: regexp}} />
    }}
  </Layout>
}

export default StorePage